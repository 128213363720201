@import url("https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap");

body {
  margin: 0;
  background-color: #12161f;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  overflow: -moz-scrollbars-none;
}

.datePicker {
  min-height: 48px;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 6px;
  border: none;
  color: #12161f;
  margin: 10px 0;
  outline: none;
  padding: 6px 12px;
}

.modal {
  width: 50%;
}

.PhoneInput {
  border: none;
  outline: none;
}

:root {
  --PhoneInput-color--focus: "#fff";
  --PhoneInputCountryFlag-borderColor: "#fff";
  --PhoneInput-borderColor--focus: "#fff";
  --PhoneInput-borderColor: "#fff";
}

iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 760px) {
  .modal {
    width: 95%;
  }
}
